import React from 'react'
import {Container, Grid, Typography, Paper, Divider} from '@material-ui/core';
import BreifcaseIcon from '../../images/iconmonstr-briefcase-icon.svg'
import DeliveryIcon from '../../images/iconmonstr-delivery-icon.svg'
import LockIcon from '../../images/lock-security.svg'
import {Link} from 'gatsby'


const ContactForm = () => {
    return (
        <Container maxWidth={false} className="contact-container">
            
            <Grid container direction="row" justify="center" alignItems="center" >
                <Grid item md={7} style={{borderRight: '1px solid #e3e0e0'}} className="left-grid">
                    <Typography className="title">Contact Us</Typography>
                    <Grid container direction="row" justify="center" alignItems="center" >
                        
                        <Grid item md={6} sm={12} xs={12} style={{textAlign: '-webkit-center', padding: '0px', marginTop: '10px'}}>
                            <Typography style={{height: '60px'}}><BreifcaseIcon/></Typography>
                            <a href="mailto:sales@blix.net" style={{color: '#000000', fontWeight: 'bold', fontSize: '28px'}}>Contact Corporate</a>
                                <Paper className="corporate-paper">
                                    <a href="mailto:sales@blix.net">
                                        <Typography style={{color: '#4571C0', fontSize: '22px', fontWeight: 'bold', marginTop: '12px'}}>Are you a business?</Typography>
                                        <Typography align='center' style={{color: '#363636', padding: '30px'}}>Reach out to discuss our corporate solutions</Typography>
                                    </a>
                                </Paper>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12} style={{textAlign: '-webkit-center', padding: '0px', marginTop: '10px'}}>
                            <Typography style={{height: '60px'}}><DeliveryIcon/></Typography>
                            <a href="mailto:support@blix.net" style={{color: '#000000', fontWeight: 'bold', fontSize: '28px'}}>Contact Support</a>
                                <Paper className="support-paper">
                                    <a href="mailto:support@blix.net">
                                        <Typography style={{color: '#4571C0', fontSize: '22px', fontWeight: 'bold', marginTop: '12px'}}>Have a question?</Typography>
                                        <Typography align='center' style={{color: '#363636', padding: '30px'}}>Send us an email and we’ll help!</Typography>
                                    </a>
                                </Paper>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item md={5} style={{textAlign: 'left', padding: '45px'}} className="contact-right-side-grid">
                    <Typography  className="title">Press & Media - Get In Touch</Typography>
                    <Typography  style={{color: '#363636', marginTop: '10px'}}>We love working with journalists around the world to share our message.</Typography>
                    <Typography  style={{color: '#363636', marginTop: '10px', marginBottom: '20px'}}>If you would like to discuss more, shoot us an email  <a href="mailto:press@blix.net" style={{color: '#1AA3FF'}}>here</a></Typography>
                    <a href="https://drive.google.com/drive/folders/1tO31HCkip9XvAZi7YJUuDhCHJBlORRDn" style={{color: '#1AA3FF', fontWeight: 'bold'}}>See our Press Kit</a>
                    <Divider style={{maxWidth: '300px', marginTop: '15px'}} />
                    <div style={{marginTop: '60px'}}>
                        <LockIcon/>
                        <Link to="/security" style={{color: '#1AA3FF', fontWeight: 'bold'}}>Security Overview</Link>
                    </div>

                </Grid>
            </Grid>

            <Container>
            <div style={{width: '50px', margin: '90px'}}></div>
                    <Typography align='center' style={{color: '#363636', padding: '5px'}}>Blix Inc.</Typography>
                    <Typography align='center' style={{color: '#363636'}}>101 Hudson Street, Jersey City, NJ 07302 </Typography>
            </Container>

        </Container>
    );
}

export default ContactForm