import React from 'react'
import ContactForm from '../components/contact-page/contact-form'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Contact = ({location}) => {
    return (
        <Layout location={location}>
            <SEO description='Blix - contact us page, support email, corporate email, question' title='Contact Us'/>
            <ContactForm/>
        </Layout>
        
    );
}

export default Contact